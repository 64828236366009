import React from "react";
import {
  Card,
  CardMedia,
  styled,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import Title from "./Title";

const Root = styled("div")({
  minWidth: 320,
  width: "100%",
});

const CustomCard = styled(Card)({
  maxWidth: 480,
  minWidth: 320,
  width: "100%",
});

const Product = ({
  href,
  image,
  title,
  description,
  children,
  onClick,
  ...other
}) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Stack spacing={1} sx={{ p: 3, pt: 0 }}>
        {typeof description === "string" ? (
          <Typography variant="body1">{description}</Typography>
        ) : (
          description
        )}
        <CustomCard {...other}>
          <CardActionArea
            component={href ? "a" : "button"}
            onClick={onClick}
            href={href}
          >
            <CardMedia alt={title} component="img" image={image} />
            <CardContent>{children}</CardContent>
          </CardActionArea>
        </CustomCard>
      </Stack>
    </Root>
  );
};

export default Product;
