import { Stack } from "@mui/material";
import React from "react";
import App from "../components/App";
import Main from "../components/Main";
import TopImage from "../components/TopImage";
import ProductGaiko from "../components/ProductGaiko";
import SnapWidget from "../components/SnapWidjet";
import ProductStamp from "../components/ProductStamp";

const IndexPage = () => {
  return (
    <App title="兵庫のスタンプコンクリートはトップクリートシステム">
      <Main>
        <TopImage />
        <Stack sx={{ maxWidth: 1280, width: 1, margin: "auto" }}>
          <ProductGaiko />
          <ProductStamp />
          <SnapWidget />
        </Stack>
      </Main>
    </App>
  );
};

export default IndexPage;
