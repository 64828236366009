import InstagramIcon from "@mui/icons-material/Instagram";
import { Stack, styled } from "@mui/material";
import React from "react";
import Title from "./Title";

const Root = styled("div")({
  position: "relative",
  padding: 24,
  overflow: "hidden",
});

const ClickListener = styled("a")({
  position: "absolute",
  width: "100%",
  height: "100%",
});

const Frame = styled("iframe")({
  border: "none",
  overflow: "hidden",
  width: "100%",
  height: "max-content",
});

const SnapWidget = (props) => {
  return (
    <Stack>
      <Title>
        <InstagramIcon />
        Instagram
      </Title>
      <Root {...props}>
        <ClickListener
          onClick={() =>
            window.open("https://www.instagram.com/topcretesystem/")
          }
        />
        <Frame
          loading="lazy"
          src="https://snapwidget.com/embed/984576"
          className="snapwidget-widget"
          allowtransparency="true"
          frameBorder="0"
          scrolling="no"
          title="SnapWidget"
        />
      </Root>
    </Stack>
  );
};

export default SnapWidget;
