import { Stack, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/EmailOutlined";
import React from "react";
import Product from "./Product";
import gaiko from "../images/gaiko.webp";
import useContact from "../hooks/useContact";

const ProductGaiko = () => {
  const contact = useContact();
  return (
    <Product
      description="外構工事、自社設計・自社施工お気軽にお問い合わせください。"
      onClick={contact}
      image={gaiko}
      title="外構工事"
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        spacing={2}
      >
        <EmailIcon />
        <Typography gutterBottom variant="h6" component="h3" fontWeight="bold">
          お問い合わせフォームはこちら
        </Typography>
      </Stack>
    </Product>
  );
};

export default ProductGaiko;
