import { Stack, Typography } from "@mui/material";
import React from "react";
import Product from "./Product";
import stamp from "../images/stamp.webp";

const ProductStamp = () => {
  return (
    <Product
      description="天然石やレンガ・タイル・ブロック等の風合いをコンクリートが美しく作り上げます。"
      href='/stamp'
      image={stamp}
      title="スタンプコンクリート"
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        direction="row"
        spacing={2}
      >
        <Typography gutterBottom variant="h6" component="h3" fontWeight="bold">
          詳しくはこちら
        </Typography>
      </Stack>
    </Product>
  );
};

export default ProductStamp;
